<template>
  <b-row
    align-h="center"
    class="mt-5"
  >
    <b-col lg="6">
      <b-card title="Sign Up">
        <validation-observer
          v-slot="{ handleSubmit }"
        >
          <b-form @submit.prevent="handleSubmit(register)">
            <b-form-row>
              <b-col lg="6">

                <validation-provider
                  #default="validationContext"
                  name="Username"
                  rules="required"
                >
                  <b-form-group
                    label="Username"
                    label-for="username"
                  >
                    <b-form-input
                      id="username"
                      v-model="form.username"
                      placeholder="Enter username"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Enter Email"
                      name
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  rules="required"
                >
                  <b-form-group
                    label="First Name"
                    label-for="first-name"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="form.first_name"
                      placeholder="Enter First name"
                      name
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="last-name"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="form.last_name"
                      placeholder="Enter Last name"
                      name
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  name="Password"
                  rules="required|confirmed:confirmation|password"
                >
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :type="passwordFieldType"
                      placeholder="Enter Password"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col lg="6">
                <validation-provider
                  #default="validationContext"
                  name="Password Confirmation"
                  rules="required|password"
                  vid="confirmation"
                >
                  <b-form-group
                    label="Password Confirmation"
                    label-for="password-confirmation"
                  >
                    <b-input-group>
                      <b-form-input
                        id="password-confirmation"
                        v-model="form.password_confirmation"
                        :type="passwordFieldType"
                        placeholder="Enter Password Confirmation"
                        :state="getValidationState(validationContext)"
                      />
                      <b-input-group-append>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          size="20"
                          class="form-control-eye ml-1"
                          @click="togglePasswordVisibility()"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>

            <b-button
              type="submit"
              variant="primary"
            >
              Register
            </b-button>
            <back />
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'Register',
  components: {
    Back,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null,
        client_id: 2,
        client_secret: '5mq3dfH8f21u7J6nMxgCKW06ZiC54uadjYavnqY8',
        grant_type: 'password',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert,
      showErrors,
      getValidationState,

    }
  },
  methods: {
    register() {
      this.$portalUsers.post('v1/register', this.form).then(() => {
        this.$swal({
          text: 'Registered Successfully',
          icon: 'success',
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            this.$router.push({ name: 'login' })
          }
        })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.form-control-eye{
  margin-top:9px
}
</style>
